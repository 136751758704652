import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'
import {Provider} from "react-redux";
import {store} from "./core/store";
import * as Sentry from "@sentry/browser";

Sentry.init({ dsn: process.env.REACT_APP_GLITCHTIP_DSN });

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
