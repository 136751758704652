import {createSlice} from '@reduxjs/toolkit'
import {getTime} from "../utils/functions";
import {TimeType} from "../../utils/constants";
import {
    cancelBooking,
    createBooking,
    fetchEvents,
    fetchLocations,
    fetchMyBookingsThunk,
    fetchSelectedBookingThunk,
    fetchSelectedRoomThunk,
    findRoomsThunk
} from "../actions/reservationActions";
import {convertDateToMoscowTimeZone} from "../../utils/functions";

const startTime = convertDateToMoscowTimeZone(getTime(TimeType.Start))
const finishTime = convertDateToMoscowTimeZone(getTime(TimeType.Finish))
const date = convertDateToMoscowTimeZone(new Date())

const reservationSlice = createSlice({
    name: 'reservation',
    initialState: {
        office: 'Офис по умолчанию',
        selectedOfficeId: null,
        locations: null,

        date,
        startTime,
        finishTime,
        numberPeople: 1,

        selectedRoomId: null,
        selectedRoomDescription: null,

        topic: null,
        headPerson: '',
        description: '',

        myBookings: [],

        selectedBookingDescription: {},
        selectedBookingId: null,

        foundRooms: [],

        events: [],

        status: null,
        loading: false,
        error: null,
    },
    reducers: {
        setOffice(state, action) {
            state.office = action.payload
        },
        setSelectedOfficeId(state, action) {
            state.selectedOfficeId = action.payload
        },
        setDate(state, action) {
            state.date = action.payload
        },
        setStartTime(state, action) {
            state.startTime = action.payload
        },
        setFinishTime(state, action) {
            state.finishTime = action.payload
        },
        setNumberPeople(state, action) {
            state.numberPeople = action.payload
        },
        setSelectedBookingId(state, action) {
            state.selectedBookingId = action.payload
        },
        setTopic(state, action) {
            state.topic = action.payload
        },
        setHeadPerson(state, action) {
            state.headPerson = action.payload
        },
        setDescription(state, action) {
            state.description = action.payload
        },
        setSelectedRoomId(state, action) {
            state.selectedRoomId = action.payload
        },
        setSelectedRoomDescription(state, action) {
            state.selectedRoomDescription = action.payload
        },
        setStatus(state, action) {
            state.status = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        clearDescriptionInfo(state) {
            state.topic = null
            state.headPerson = ''
            state.description = ''
        },
    },
    extraReducers: {
        [fetchLocations.pending]: (state) => {
            state.loading = true
            state.locations = null
        },
        [fetchLocations.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.locations = payload.locations
        },
        [fetchLocations.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [findRoomsThunk.pending]: (state) => {
            state.loading = true
            state.foundRooms = []
            state.selectedRoomId = null
            state.selectedRoomDescription = null
        },
        [findRoomsThunk.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.foundRooms = payload
        },
        [findRoomsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchMyBookingsThunk.pending]: (state) => {
            state.loading = true
            state.myBookings = []
        },
        [fetchMyBookingsThunk.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.myBookings = payload
        },
        [fetchMyBookingsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchSelectedBookingThunk.pending]: (state) => {
            state.loading = true
            state.selectedBookingDescription = null
        },
        [fetchSelectedBookingThunk.fulfilled]: (state, {payload}) => {
            state.selectedBookingDescription = payload
            state.loading = false
        },
        [fetchSelectedBookingThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchSelectedRoomThunk.pending]: (state) => {
            state.loading = true
            state.selectedRoomDescription = null
        },
        [fetchSelectedRoomThunk.fulfilled]: (state, {payload}) => {
            state.selectedRoomDescription = payload
            state.loading = false
        },
        [fetchSelectedRoomThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [fetchEvents.pending]: (state) => {
            state.loading = true
            state.events = []
        },
        [fetchEvents.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.events = payload
        },
        [fetchEvents.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [createBooking.pending]: (state) => {
            state.loading = true
            state.status = null
        },
        [createBooking.fulfilled]: (state, {payload}) => {
            state.status = payload
            state.loading = false
        },
        [createBooking.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },

        [cancelBooking.pending]: (state) => {
            state.loading = true
            state.status = null
        },
        [cancelBooking.fulfilled]: (state, {payload}) => {
            state.status = payload
            state.loading = false
        },
        [cancelBooking.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },
    }
})

export const {setError, setStatus, setOffice, setSelectedOfficeId, setDate, setSelectedBookingId, setTopic, setDescription, setHeadPerson, clearDescriptionInfo, setStartTime, setFinishTime, setNumberPeople, setSelectedRoomId, setSelectedRoomDescription} = reservationSlice.actions

export default reservationSlice.reducer
