import {createSlice} from '@reduxjs/toolkit'
import {
    fetchBookingsByRoomsThunk,
} from "../actions/bookingManagementActions";
import {getCurrentMoscowTime} from "../../utils/functions";

const bookingManagementSlice = createSlice({
    name: 'bookingManagement',
    initialState: {
        bookingsByRooms: [],

        date: getCurrentMoscowTime(),

        loading: false,
        error: null,
    },
    reducers: {
        setDate(state, action) {
            state.date = action.payload
        },
        setBookingsByRooms(state, action) {
            state.bookingsByRooms = action.payload
        },
    },
    extraReducers: {

        [fetchBookingsByRoomsThunk.pending]: (state) => {
            state.loading = true
        },
        [fetchBookingsByRoomsThunk.fulfilled]: (state, {payload}) => {
            state.bookingsByRooms = payload
            state.loading = false
        },
        [fetchBookingsByRoomsThunk.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
        },
    }
})

export const {setDate, setBookingsByRooms} = bookingManagementSlice.actions

export default bookingManagementSlice.reducer