import {createAsyncThunk} from "@reduxjs/toolkit";
import ReservationService from "../../services/ReservationService";


export const fetchBookingsByRoomsThunk = createAsyncThunk(
    'bookingManagement/fetchBookingsByRoomsThunk',
    async ({date, searchText, officeFilterId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getBookingsByRooms(date.setUTCHours(0,0,0,0), searchText)
            if (officeFilterId) {
                return data.rooms.filter(room => room.location.id === officeFilterId)
            }
            else return data.rooms

        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)