import React, {memo} from 'react';
import './FilterButton.css';
import {useTelegram} from "../../../core/hooks/useTelegram";

function FilterButton({title, text, icon, modalName, onClick}) {
    const {tg} = useTelegram()
    const onButtonClick = () => {
        onClick(modalName)
        tg.HapticFeedback.impactOccurred('light')
    }
    return (
        <button
            className='filter-button'
            onClick={onButtonClick}>
            <div className='icon-container'>
                <img className='icon' src={`/img/icons/${icon}.svg`} alt={icon} />
            </div>
            <div className='filter-description'>
                <div className='filter-title'>
                    {title}
                </div>
                <div className='filter-text'>
                    {text}
                </div>
            </div>
        </button>
    )
}

export default memo(FilterButton)