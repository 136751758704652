import axios from 'axios';
import AuthService from "../services/AuthService";


export const API_URL = process.env.REACT_APP_API_URL

export const api = axios.create({
    baseURL: API_URL
})

let refreshTokenRequest = null;

api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    return config;
})

api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && error.config && !error.config.isRetry) {
        originalRequest.isRetry = true;

        try {
            if (refreshTokenRequest === null) {
                refreshTokenRequest = AuthService.refreshWithHeader(localStorage.getItem('refresh_token'))
            }

            const response = await refreshTokenRequest

            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);

            refreshTokenRequest = null

            originalRequest.headers = { ...originalRequest.headers };
            originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`;

            return api.request(originalRequest);
        } catch (e) {
            alert('Ошибка авторизации')
        }
    }

    throw error;
})
