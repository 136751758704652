import React from 'react';
import './BookingListItem.css'
import {useDispatch} from "react-redux";
import {setFinishTime, setSelectedRoomId, setStartTime} from "../../core/store/slices/reservationSlice";
import {formatTime} from "../../core/utils/functions";
import {useNavigate} from "react-router-dom";
import {PrivateRoutes} from "../../core/utils/constants";
import {useTelegram} from "../../core/hooks/useTelegram";

function BookingListItem({id, photo, title, size=0, timesButtons, withoutRedirectClicking}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {tg} = useTelegram()

    const selectRoom = () => {
        dispatch(setSelectedRoomId(id))
        navigate(PrivateRoutes.RoomDescription)
        tg.HapticFeedback.impactOccurred('light')
    }

    const selectTime = (startTime, finishTime) => {
        dispatch(setStartTime(new Date(startTime)))
        dispatch(setFinishTime(new Date(finishTime)))
        dispatch(setSelectedRoomId(id))
        navigate(PrivateRoutes.RoomDescription)
    }

    return (
        <div className='booking-list-item'>
            <div className='booking-list-item-container' onClick={withoutRedirectClicking ? undefined : selectRoom}>
                {photo ? <img className='booking-list-item-pic' src={photo.base64} alt="Room Photo" /> : <img className='booking-list-item-pic' src='/img/rooms/default_room.png' alt={`${photo}`} />}
                <div className='booking-list-item-size'>
                    <img className='booking-list-item-size-pic' src='/img/icons/flag.svg' alt={`${size}`} />
                    <span className='booking-list-item-size-text'>до {size.length===1 ? size+'\u00A0' : size}<br />чел</span>
                </div>
                <div className='booking-list-item-title'>
                    {title}
                </div>
            </div>
            {
                timesButtons &&
                <div className='booking-list-panel-times-buttons'>
                    {timesButtons.map((button, index) =>
                        <div key={index} className='booking-list-times-button' onClick={() => selectTime(button.start_time*1000, button.finish_time*1000)}>
                            {formatTime(new Date(button.start_time*1000))}-{formatTime(new Date(button.finish_time*1000))}
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

export default BookingListItem;