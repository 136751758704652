import React from 'react';
import './Booking.css'
import {useTelegram} from "../../../core/hooks/useTelegram";

function Booking({id, photo, title, subtitle, onClick}) {
    const {tg} = useTelegram()
    const onBookingClick = () => {
        onClick(id)
        tg.HapticFeedback.impactOccurred('light')
    }
    return (
        <div className='booking' onClick={onBookingClick}>
            {photo ? <img className='room-pic' src={photo.base64} alt="Room Photo" /> : <img className='room-pic' src='/img/rooms/default_room.png' alt={`${photo}`} />}
            <div className='booking-description'>
                <div className='booking-title'>{title}</div>
                <div className='booking-text'>{subtitle}</div>
            </div>
        </div>
    );
}

export default Booking;