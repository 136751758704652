import React, {useCallback, useEffect, useState} from 'react';
import './Reservation.css';
import Header from '../../../components/Header/Header';
import Button from "../../../components/UI/Button/Button";
import FilterButton from "../../../components/UI/FilterButton/FilterButton";
import YourBookings from "../../../components/MyBookings/MyBookings";
import OfficePicker from "../../../components/modals/OfficePicker/OfficePicker";
import TimePicker from "../../../components/modals/TimePicker/TimePicker";
import NumberPeople from "../../../components/modals/NumberPeople/NumberPeople";
import DayPicker from "../../../components/modals/DayPicker/DayPicker";
import WeekDays from "../../../components/UI/WeekDays/WeekDays";
import {Keys, Modals, PrivateRoutes} from "../../../core/utils/constants";
import {useNavigate} from "react-router-dom";
import {formatTime} from "../../../core/utils/functions";
import {useDispatch, useSelector} from "react-redux";
import {fetchLocations} from "../../../core/store/actions/reservationActions";
import {setOffice, setSelectedOfficeId} from "../../../core/store/slices/reservationSlice";
import ReservationService from "../../../core/services/ReservationService";
import DefaultModal from "../../../components/modals/DefaultModal/DefaultModal";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {useTelegram} from "../../../core/hooks/useTelegram";


function ScreenReservation() {
    useEffect(() => {
        dispatch(fetchLocations({}))
        async function fetchOffice() {
            if (!selectedOfficeId) {
                const response = await ReservationService.getCurrentLocation()
                if (response.data.location) {
                    dispatch(setSelectedOfficeId(response.data.location.id))
                    dispatch(setOffice(response.data.location.name))
                }
            }
        }
        fetchOffice();
    }, [])

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {tg} = useTelegram()

    const { office, date, startTime, finishTime, numberPeople, selectedOfficeId } = useSelector((state) => state.reservation)

    const [status, setStatus] = useState('')

    const [openModal, setOpenModal] = useState('')

    const closeModal = useCallback(() => {
        setOpenModal('')
    }, [])
    const toggleModal = useCallback((modal) => {
        setOpenModal(modal)
    }, [])

    const findBooking = () => {
        if (selectedOfficeId) {
            navigate(PrivateRoutes.FoundRooms)
        }
        else {
            setStatus('Сначала выбери офис!')
            setOpenModal(Modals.Error)
            tg.HapticFeedback.notificationOccurred('error')
        }
    }

    const roomsBrowse = () => {
        navigate(PrivateRoutes.BookingRoomsBrowse)
    }

    useKeyUp(Keys.KEY_NAME_ENTER, openModal==='' ? findBooking : undefined)

    return (
        <div className='main-screen'>
            <Header
                title={openModal===Modals.OfficePicker ? 'твое местоположение' : 'бронирование переговорок'}
                subtitle={getSubtitle(openModal)}
                component={openModal===Modals.DatePicker ? <WeekDays /> : null}
                selectedOffice={office}
                modalName={openModal===Modals.OfficePicker ? '' : Modals.OfficePicker}
                onClickOffice={toggleModal}
            />
            <div className='container'>
                {openModal===Modals.OfficePicker && <OfficePicker closeModal={closeModal}/>}
                {openModal===Modals.DatePicker && <DayPicker closeModal={closeModal}/>}
                {openModal===Modals.TimePicker && <TimePicker closeModal={closeModal}/>}
                {openModal===Modals.NumberPeople && <NumberPeople closeModal={closeModal}/>}
                {openModal===Modals.Error && <DefaultModal title={status} buttonText='Готово' onButtonClick={closeModal} />}

                <FilterButton
                    title='Дата'
                    text={date.toLocaleDateString()}
                    icon='Date'
                    modalName={Modals.DatePicker}
                    onClick={toggleModal}
                />
                <FilterButton
                    title='Удобное время'
                    text={
                        `${formatTime(startTime)} - ${formatTime(finishTime)}`
                    }
                    icon='Time'
                    modalName={Modals.TimePicker}
                    onClick={toggleModal}
                />
                <FilterButton
                    title='Кол-во человек'
                    text={numberPeople}
                    icon='People'
                    modalName={Modals.NumberPeople}
                    onClick={toggleModal}
                />

                <Button text='Найти переговорку' onClick={findBooking} />
                <Button text='Занятость переговорок' color='black' onClick={roomsBrowse} />

                <YourBookings />
            </div>
        </div>
    )
}

function getSubtitle(modalName) {
    switch (modalName) {
        case Modals.DatePicker:
            return 'Дата бронирования'
        case Modals.Description:
            return 'Описание мероприятия'
        default:
            return ''
    }
}

export default ScreenReservation;