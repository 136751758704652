import React, {memo, useCallback, useState} from 'react';
import './TimePicker.css';
import Button from "../../UI/Button/Button";
import {useTelegram} from "../../../core/hooks/useTelegram";
import {Keys, TimeType} from "../../../core/utils/constants";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroller from "../../UI/InfiniteScroller/InfiniteScroller";
import {setFinishTime, setStartTime} from "../../../core/store/slices/reservationSlice";
import {useKeyUp} from "../../../core/hooks/useKeyUp";

const hours = [
    {id:8, value:'08', number: 8},{id:9, value:'09', number: 9},{id:10, value:'10', number: 10},
    {id:11, value:'11', number: 11},{id:12, value:'12', number: 12},{id:13, value:'13', number: 13},
    {id:14, value:'14', number: 14},{id:15, value:'15', number: 15},{id:16, value:'16', number: 16},
    {id:17, value:'17', number: 17},{id:18, value:'18', number: 18}
]

const minutes = [
    {id:0, value:'00', number: 0},
    {id:1, value:'15', number: 15},
    {id:2, value:'30', number: 30},
    {id:3, value:'45', number: 45},
]

function TimePicker({closeModal}) {
    const {startTime, finishTime} = useSelector((state) => state.reservation)
    const dispatch = useDispatch()

    const {tg} = useTelegram()

    useKeyUp(Keys.KEY_NAME_ENTER, closeModal)

    const [timeType, setTimeType] = useState(TimeType.Start)

    const setStartHour = useCallback((index) => {
        dispatch(setStartTime(new Date(startTime.setHours(hours[index].number))))
    }, [])

    const setFinishHour = useCallback((index) => {
        dispatch(setFinishTime(new Date(finishTime.setHours(hours[index].number))))
    }, [])

    const setStartMinute = useCallback((index) => {
        dispatch(setStartTime(new Date(startTime.setMinutes(minutes[index].number))))
    }, [])

    const setFinishMinute = useCallback((index) => {
        dispatch(setFinishTime(new Date(finishTime.setMinutes(minutes[index].number))))
    }, [])

    const readyButton = () => {
        closeModal()
    }

    return (
        <div className='time-container'>
            <div className='time-picker'>
                <div className='time-picker-title'>Выбери время</div>
                <div className='time-picker-buttons-panel'>
                    <div
                        className={'time-picker-button-start ' + (timeType === TimeType.Start ? 'active-button' : '')}
                        onClick={() => setTimeType(TimeType.Start)}
                    >
                        {formatDate(startTime)}
                    </div>
                    {' - '}
                    <div
                        className={'time-picker-button-finish ' + (timeType === TimeType.Finish ? 'active-button' : '')}
                        onClick={() => setTimeType(TimeType.Finish)}
                    >
                        {formatDate(finishTime)}
                    </div>
                </div>
                <div className='time-picker-modal'>
                    <div className='time-picker-modal-chooser'></div>
                    <div className='time-picker-modal-title'>
                        {timeType === TimeType.Start ? 'Начало совещания' : 'Конец совещания'}
                    </div>
                    <div className='time-picker-modal-hours'>
                        <div className='time-picker-modal-hours-title'>
                            часы
                        </div>
                        <div className='time-picker-modal-hours-scroller'>
                            {timeType === TimeType.Start &&
                                <InfiniteScroller
                                    data={hours}
                                    onSelect={setStartHour}
                                    defaultIndex={startTime.getHours() - 8}
                                />
                            }
                            {timeType === TimeType.Finish &&
                                <InfiniteScroller
                                    data={hours}
                                    onSelect={setFinishHour}
                                    defaultIndex={finishTime.getHours() - 8}
                                />
                            }
                        </div>
                    </div>
                    <div className='time-picker-modal-minutes'>
                        <div className='time-picker-modal-minutes-title'>
                            минуты
                        </div>
                        <div className='time-picker-modal-minutes-scroller'>
                            {timeType === TimeType.Start &&
                                <InfiniteScroller
                                    data={minutes}
                                    onSelect={setStartMinute}
                                    defaultIndex={minutes.filter(el => el.number===startTime.getMinutes())[0].id}
                                />
                            }
                            {timeType === TimeType.Finish &&
                                <InfiniteScroller
                                    data={minutes}
                                    onSelect={setFinishMinute}
                                    defaultIndex={minutes.filter(el => el.number===finishTime.getMinutes())[0].id}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='button-footer'>
                <Button
                    text='Готово'
                    onClick={readyButton}
                />
            </div>
        </div>
    )
}

function formatDate(date) {
    return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`
}

export default memo(TimePicker)
