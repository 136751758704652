import {api, API_URL} from "../api";
import axios from "axios";

export default class AuthService {
    
    static async tgWebAppSignIn(auth_data) {
        return api.post('/tg-web-app-sign-in', {auth_data})
    }

    static async refreshWithCookie() {
        return axios.get(`${API_URL}/refresh`, {withCredentials: true})
    }

    static async refreshWithHeader(refreshToken) {
        return axios.get(`${API_URL}/tg-or-viewer-refresh`, {
            headers: {
                Authorization: `Bearer ${refreshToken}`
            }
        })
    }

}
