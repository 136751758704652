import React, {useState} from 'react';
import './DayPicker.css';
import Button from "../../UI/Button/Button";
import DatePicker, {setDefaultLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import {useDispatch, useSelector} from "react-redux";
import {setDate} from "../../../core/store/slices/reservationSlice";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";
import {getCurrentMoscowTime} from "../../../core/utils/functions";
import {useTelegram} from "../../../core/hooks/useTelegram";
setDefaultLocale(ru)

function DayPicker({closeModal}) {
    const {date} = useSelector((state) => state.reservation)
    const dispatch = useDispatch()
    const {tg} = useTelegram()

    useKeyUp(Keys.KEY_NAME_ENTER, closeModal)

    const onBookingClick = (date) => {
        dispatch(setDate(date))
        tg.HapticFeedback.impactOccurred('light')
    }

    return (
        <div className='date-container'>
            <div className='date-calendar'>
                <DatePicker
                    selected={date}
                    minDate={getCurrentMoscowTime()}
                    onChange={onBookingClick}
                    monthsShown={5}
                    disabledKeyboardNavigation
                    inline
                />
            </div>
            <div className='button-footer'>
                <Button text='Готово' onClick={closeModal}/>
            </div>
        </div>
    )
}

export default DayPicker
