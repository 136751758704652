import React, {useEffect, useState} from 'react';
import './FoundRooms.css'
import {findRoomsThunk} from "../../../../core/store/actions/reservationActions";
import {useDispatch, useSelector} from "react-redux";
import Header from "../../../../components/Header/Header";
import {formatTime} from "../../../../core/utils/functions";
import BookingList from "../../../../components/BookingList/BookingList";
import ColorRingLoader from "../../../../components/UI/ColorRingLoader/ColorRingLoader";
import {useTelegram} from "../../../../core/hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import DescriptionSetter from "../../../../components/modals/DescriptionSetter/DescriptionSetter";
import {setDate, setError} from "../../../../core/store/slices/reservationSlice";
import {useSwipeable} from "react-swipeable";
import DefaultModal from "../../../../components/modals/DefaultModal/DefaultModal";
import {useKeyUp} from "../../../../core/hooks/useKeyUp";
import {Keys, PrivateRoutes} from "../../../../core/utils/constants";

function ScreenFoundRooms() {
    const {tg} = useTelegram()
    const navigate = useNavigate()

    const {loading, error, selectedOfficeId, foundRooms, office, date, startTime, finishTime, numberPeople, selectedRoomId} = useSelector((state) => state.reservation)
    const dispatch = useDispatch()
    const [handAnim, setHandAnim] = useState(false)

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (foundRooms?.alternative_size?.length===0 && foundRooms?.alternative_time?.length===0 && foundRooms?.relevant?.length===0) {
                tg.HapticFeedback.impactOccurred('light')
                const newDate = new Date(date.getTime() + (24 * 60 * 60 * 1000))
                dispatch(setDate(newDate))
                dispatch(findRoomsThunk({selectedOfficeId, date: newDate, startTime, finishTime, numberPeople}))
                setHandAnim(false)
            }
        },
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    useKeyUp(Keys.KEY_NAME_ESC, () => navigate(PrivateRoutes.Reservation))

    useEffect(() => {
        tg.BackButton.show()
        tg.BackButton.onClick(() => {
            navigate(PrivateRoutes.Reservation)
            tg.HapticFeedback.impactOccurred('light')
        })

        return () => {
            tg.BackButton.hide()
            tg.BackButton.offClick(() => {
                navigate(PrivateRoutes.Reservation)
                tg.HapticFeedback.impactOccurred('light')
            })
        }
    }, [])

    useEffect(() => {
        if (selectedOfficeId && date && startTime && finishTime && numberPeople)
            dispatch(findRoomsThunk({selectedOfficeId, date, startTime, finishTime, numberPeople}))
    }, [])

    const onErrorModalClick = () => {
        dispatch(setError(null))
        navigate(PrivateRoutes.Reservation)
    }

    useEffect(() => {
        if (error)
            tg.HapticFeedback.notificationOccurred('error')
    },[error])

    return (
        <div className='found-rooms-screen'>
            {selectedRoomId && <DescriptionSetter />}

            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={onErrorModalClick} />}

            <Header
                title={'бронирование переговорок'}
                selectedOffice={office}
                selectedDate={date.toLocaleDateString()}
            />

            <div
                className='found-rooms-list'
                {...handlers}
            >
                <div className='found-rooms-relevant'>
                    <div className='found-rooms-title'>
                        Подходящие результаты
                        <span className='found-rooms-title-time'>НА {formatTime(startTime)}-{formatTime(finishTime)}</span>
                    </div>
                    {
                        !loading ? foundRooms?.relevant?.length ?
                            <BookingList rooms={foundRooms?.relevant}/> : <div className='found-rooms-subtitle'>Подходящих вариантов нет</div> : undefined
                    }
                </div>

                <div className='found-rooms-alternative'>
                    {loading && <ColorRingLoader />}
                    {(foundRooms?.alternative_size?.length > 0 || foundRooms?.alternative_time?.length > 0) && <div className='found-rooms-title'>Альтернативы</div>}
                    {
                        foundRooms?.alternative_size?.length > 0 &&
                        <div>
                            <div className='found-rooms-subtitle'>Подходящее время</div>
                            <BookingList rooms={foundRooms?.alternative_size} />
                        </div>
                    }
                    {
                        foundRooms?.alternative_time?.length > 0 &&
                        <div>
                            <div className='found-rooms-subtitle'>Подходящий размер</div>
                            <BookingList rooms={foundRooms?.alternative_time} withTimesButtons={true} />
                        </div>
                    }
                    {
                        foundRooms?.alternative_size?.length===0 && foundRooms?.alternative_time?.length===0 && foundRooms?.relevant?.length===0 &&
                        <div>
                            <div className='found-rooms-subtitle'>Альтернатив нет</div>
                        </div>
                    }
                </div>
                {
                    handAnim &&
                    <div>
                        <img className='hand-icon' src='/img/icons/Hand.svg' alt='Location' />
                    </div>
                }
            </div>
        </div>
    )
}

export default ScreenFoundRooms;