export const Modals = {
    OfficePicker: 'OfficePicker',
    DatePicker: 'DatePicker',
    TimePicker: 'TimePicker',
    NumberPeople: 'NumberPeople',
    Description: 'Description',

    Confirmed: 'Confirmed',
    Error: 'Error',
}

export const PublicRoutes = {
    SignIn: '/tg-web-app-sign-in',
}

export const PrivateRoutes = {
    Reservation: '/reservation',
    FoundRooms: '/found-rooms',
    MyBookingDescription: '/my-booking-description',
    BookingDescription: '/booking-description',
    RoomDescription: '/room-description',
    BookingRoomsBrowse: '/booking-rooms-browse',

    RoomEditing: '/room-editing'
}


export const HourLimits = {
    UP: 8,
    DOWN: 18
}

export const TimeType = {
    Start: 'Start',
    Finish: 'Finish'
}

export const minutes = [
    {id:2, value:'30', number: 30},
    {id:3, value:'00', number: 0},
]

export const hours = [
    {id:8, value:'08', number: 8},{id:9, value:'09', number: 9},{id:10, value:'10', number: 10},
    {id:11, value:'11', number: 11},{id:12, value:'12', number: 12},{id:13, value:'13', number: 13},
    {id:14, value:'14', number: 14},{id:15, value:'15', number: 15},{id:16, value:'16', number: 16},
    {id:17, value:'17', number: 17},{id:18, value:'18', number: 18},
]

export const TypeFoundRooms = {
    ALTERNATIVE_ALL: 'Alternative all',
    ALTERNATIVE_TIME: 'Alternative time',
    ALTERNATIVE_SIZE: 'Alternative size',
    RELEVANT: 'Relevant',
    NOT_FOUND: 'Not found'
}

export const Keys = {
    KEY_NAME_ESC: 'Escape',
    KEY_NAME_ENTER: 'Enter',
}