import React from 'react';
import './OfficePicker.css';
import Button from "../../UI/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {setOffice, setSelectedOfficeId} from "../../../core/store/slices/reservationSlice";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";
import {useTelegram} from "../../../core/hooks/useTelegram";


function OfficePicker({closeModal}) {
    const {selectedOfficeId, locations} = useSelector((state) => state.reservation)

    const dispatch = useDispatch()

    useKeyUp(Keys.KEY_NAME_ENTER, closeModal)
    const {tg} = useTelegram()
    const selectOffice = (officeId, officeName) => {
        dispatch(setSelectedOfficeId(officeId))
        dispatch(setOffice(officeName))
        tg.HapticFeedback.impactOccurred('light')
    }

    let officesComponent = locations?.map(city =>
        <div className='city-office-content' key={city.id}>
            <span className='city-content'>{city.name}</span>
            {city.sublocations.map(office =>
                <div className={'office-content ' + (selectedOfficeId===office.id ? 'office-active' : '')} key={office.id} onClick={() => {
                    selectOffice(office.id, office.name)
                }}>
                    {office.name}
                </div>
            )}
        </div>
    )

    return (
        <div className='office-container'>
            <div className='office-list'>
                {officesComponent}
            </div>
            <img className='office-icon' src='/img/icons/Location.svg' alt='Location' />
            <div className='button-footer'>
                <Button text='Готово' onClick={closeModal}/>
            </div>
        </div>
    )
}

export default OfficePicker