import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './BookingRoomsBrowse.css'
import {useTelegram} from "../../../../core/hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import {Modals, PrivateRoutes} from "../../../../core/utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {fetchBookingsByRoomsThunk} from "../../../../core/store/actions/bookingManagementActions";
import OfficePicker from "../../../../components/modals/OfficePicker/OfficePicker";
import Header from "../../../../components/Header/Header";
import {getCurrentMoscowTime} from "../../../../core/utils/functions";
import {setDate} from "../../../../core/store/slices/bookingManagementSlice";
import ColorRingLoader from "../../../../components/UI/ColorRingLoader/ColorRingLoader";
import Button from "../../../../components/UI/Button/Button";
import {Calendar, Views, momentLocalizer} from "react-big-calendar";
import moment from 'moment'
import 'moment/locale/ru';

// and, for optional time zone support
import 'moment-timezone'
moment.tz.setDefault('Europe/Moscow')
moment.locale('ru')
// end optional time zone support


function ScreenBookingRoomsBrowse(props) {
    const {tg} = useTelegram()
    const navigate = useNavigate()
    const localizer = momentLocalizer(moment)

    const dispatch = useDispatch();
    const { office, selectedOfficeId, locations } = useSelector((state) => state.reservation)
    const { date, bookingsByRooms } = useSelector((state) => state.bookingManagement);

    const columns = ['Переговорка'];

    useEffect(() => {
        tg.BackButton.show()
        tg.BackButton.onClick(() => {
            navigate(PrivateRoutes.Reservation)
            tg.HapticFeedback.impactOccurred('light')
        })

        return () => {
            tg.BackButton.hide()
            tg.BackButton.offClick(() => {
                navigate(PrivateRoutes.Reservation)
                tg.HapticFeedback.impactOccurred('light')
            })
        }
    }, [])

    useEffect(() => {
        dispatch(fetchBookingsByRoomsThunk({date, officeFilterId: selectedOfficeId}))
    }, [date, selectedOfficeId])

    useEffect(() => {
        let interval;
        function startTimer() {
            interval = setInterval(async () => {
                await dispatch(fetchBookingsByRoomsThunk({date, officeFilterId: selectedOfficeId}))
            }, 5000)
        }
        startTimer()
        return () => clearInterval(interval)
    }, [date, selectedOfficeId])

    useEffect(() => {
        let rows = [];
        bookingsByRooms.map((element, index) =>
            rows.push([
                element.name,
            ])
        );
        setBookings(rows);

        let items = []
        const colors = [
            "#929397", "#68A2AA", "#9BD5C1", "#AAE0E7", "#C679A5", "#C987DC",
            "#CDD6C5", "#D5C2C6", "#DE5CA1", "#E4BCEE", "#F38780", "#FDEFC6",
        ]
        bookingsByRooms.map((room, room_index) =>
            room.bookings.map((booking, ind) => {
                items.push({
                    id: booking.id,
                    resourceId: room_index+1,
                    start: new Date((getCurrentMoscowTime()).setHours(new Date(booking.start_time * 1000).getHours(), new Date(booking.start_time * 1000).getMinutes(), 0, 0)),
                    end: new Date((getCurrentMoscowTime()).setHours(new Date(booking.finish_time * 1000).getHours(), new Date(booking.finish_time * 1000).getMinutes(), 0, 0)),
                })
            })
        )
        setTimeLineItems(items);

        const groups = bookingsByRooms.map((room, room_index) => ({ resourceId: room_index + 1, title: room.name }));

        setTimeLineGroups(groups);

        if (bookingsByRooms.length > 0 && loading) {
            setLoading(false)
        }

    }, [bookingsByRooms]);

    const [openModal, setOpenModal] = useState('')
    const [bookings, setBookings] = useState([]);
    const [timeLineItems, setTimeLineItems] = useState([]);
    const [timeLineGroups, setTimeLineGroups] = useState([]);
    const [loading, setLoading] = useState(true)

    const closeModal = useCallback(() => {
        setOpenModal('')
        setLoading(true)
    }, [])
    const toggleModal = useCallback((modal) => {
        setOpenModal(modal)
    }, [])

    const changeDate = (type) => {
        let newDate
        if (type==='next')
            newDate = new Date(date.getTime() + (24 * 60 * 60 * 1000))
        else if (type==='prev')
            newDate = new Date(date.getTime() - (24 * 60 * 60 * 1000))
        setLoading(true)
        dispatch(setDate(newDate))
        tg.HapticFeedback.impactOccurred('light')
    }


    return (
        <div className='booking-management-screen'>
            {(openModal === Modals.OfficePicker) && (
                <OfficePicker closeModal={closeModal} />
            )}

            <Header
                title='Занятость переговорок'
                selectedOffice={office}
                modalName={openModal===Modals.OfficePicker ? '' : Modals.OfficePicker}
                onClickOffice={toggleModal}
            />

            <div className='booking-rooms-browse-container'>
                <div className='booking-rooms-browse-content-wrapper'>
                    <div className='booking-management-date-picker'>
                        <svg style={{ transform: 'rotate(180deg)' }} onClick={() => changeDate('prev')} width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M8.1543 6.5L0.538913 12.1292L0.538913 0.870835L8.1543 6.5Z' fill='black' />
                        </svg>

                        {date.toLocaleDateString()}

                        <svg onClick={() => changeDate('next')} width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M8.1543 6.5L0.538913 12.1292L0.538913 0.870835L8.1543 6.5Z' fill='black' />
                        </svg>
                    </div>

                    {loading ?
                        <ColorRingLoader />
                        :
                        <div className='booking-management-main-table'>
                            <Calendar
                                defaultView={Views.DAY}
                                toolbar={false}
                                allDayAccessor={undefined}
                                min={date.setHours(8, 0, 0)}
                                max={date.setHours(19, 0, 0)}
                                events={timeLineItems}
                                localizer={localizer}
                                resourceIdAccessor="resourceId"
                                resources={timeLineGroups}
                                resourceTitleAccessor="title"
                                step={30}
                            />
                        </div>
                    }
                </div>
                <div className='button-footer'>
                    <Button text='Готово' onClick={() => navigate(PrivateRoutes.Reservation)}/>
                </div>
            </div>
        </div>
    );
}

export default ScreenBookingRoomsBrowse;