import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTelegram } from '../../../core/hooks/useTelegram';
import { tgWebAppSignInThunk } from '../../../core/store/actions/authActions';
import { PrivateRoutes } from '../../../core/utils/constants';


function ScreenTgLogin() {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const { auth_data } = useTelegram()

    useEffect(() => {
        if (user) {
            navigate(PrivateRoutes.Reservation)
        } else if (auth_data) {
            dispatch(tgWebAppSignInThunk({ auth_data }))
        } else {
            // NOTE: The app is opened not via any Telegram Client, do nothing.
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ navigate, user ])
}


export default ScreenTgLogin;
