import {api, API_URL} from "../api";
import axios from "axios";
import {useSelector} from "react-redux";

export default class ReservationService {
    static async getLocations() {
        return api.post('/get-locations')
    }

    static async findRooms(location, booking_date, booking_time_start, booking_time_finish, capacity) {
        return api.post('/find-rooms', {location, booking_date, booking_time_start, booking_time_finish, capacity})
    }

    static async getUserBookings(user_id) {
        return api.post('/get-user-bookings', {user_id})
    }

    static async getBooking(booking_id) {
        return api.post('/get-booking', {booking_id})
    }

    static async getRoomInfo(roomId) {
        return api.post('/get-room-info', {roomId})
    }

    static async getAllEvents() {
        return api.post('/get-event-types')
    }

    static async bookingCreate(room, booking_date, booking_time_start, booking_time_finish, description, event_type, tenant) {
        return api.post('/create-booking', {
            room, booking_date, booking_time_start, booking_time_finish, description, event_type, tenant
        })
    }

    static async bookingCancel(booking_id) {
        return api.post('/cancel-booking', {booking_id})
    }

    static async getCurrentLocation() {
        return api.post('/get-current-location')
    }

    static async getBookingsByRooms(booking_date, search='') {
        return api.post('/get-booking-list-by-rooms', { booking_date, search })
    }
}