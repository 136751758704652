import { configureStore } from "@reduxjs/toolkit";
import authReducer from './slices/authSlice'
import reservationReducer from "./slices/reservationSlice";
import bookingManagementReducer from "./slices/bookingManagementSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        reservation: reservationReducer,
        bookingManagement: bookingManagementReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})