import React from 'react';
import Button from "../../UI/Button/Button";
import "./DefaultModal.css";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";

function DefaultModal({title, buttonText, onButtonClick}) {

    useKeyUp(Keys.KEY_NAME_ESC, onButtonClick)
    useKeyUp(Keys.KEY_NAME_ENTER, onButtonClick)

    return (
        <div className='default-modal-bg'>
            <div className='default-modal-main'>
                <div className='default-modal-title'>
                    {title}
                </div>
                <div className='default-modal-button'>
                    <Button text={buttonText} onClick={onButtonClick} />
                </div>
            </div>
        </div>
    );
}

export default DefaultModal;