import React, {useEffect, useState} from 'react';
import './MyBookingDescription.css'
import Header from "../../../../components/Header/Header";
import ColorRingLoader from "../../../../components/UI/ColorRingLoader/ColorRingLoader";
import Button from "../../../../components/UI/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import BookingListItem from "../../../../components/BookingListItem/BookingListItem";
import {cancelBooking, fetchSelectedBookingThunk} from "../../../../core/store/actions/reservationActions";
import {useNavigate} from "react-router-dom";
import DefaultModal from "../../../../components/modals/DefaultModal/DefaultModal";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal/ConfirmationModal";
import {setError, setStatus} from "../../../../core/store/slices/reservationSlice";
import {useKeyUp} from "../../../../core/hooks/useKeyUp";
import {Keys, PrivateRoutes} from "../../../../core/utils/constants";
import {useTelegram} from "../../../../core/hooks/useTelegram";

function ScreenMyBookingDescription(props) {
    const {error, status, loading, selectedBookingDescription, selectedBookingId} = useSelector((state) => state.reservation)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tg } = useTelegram()

    useKeyUp(Keys.KEY_NAME_ENTER, () => navigate(PrivateRoutes.Reservation))
    useKeyUp(Keys.KEY_NAME_ESC, () => navigate(PrivateRoutes.Reservation))

    useEffect(() => {
        if (selectedBookingId)
            dispatch(fetchSelectedBookingThunk({selectedBookingId}))

        tg.BackButton.show()
        tg.BackButton.onClick(() => {
            navigate(PrivateRoutes.Reservation)
            tg.HapticFeedback.impactOccurred('light')
        })

        return () => {
            tg.BackButton.hide()
            tg.BackButton.offClick(() => {
                navigate(PrivateRoutes.Reservation)
                tg.HapticFeedback.impactOccurred('light')
            })
        }
    }, [])

    const [localOpenModal, setLocalOpenModal] = useState('')

    const onCancelBookingClick = () => {
        setLocalOpenModal('Отменить')
    }

    const onConfirmCancelingBookingClick = () => {
        setLocalOpenModal('')
        dispatch(cancelBooking({selectedBookingId}))
    }

    const onRejectCancelingBookingClick = () => {
        setLocalOpenModal('')
    }

    const onModalCompleteExit = () => {
        dispatch(setStatus(null))
        navigate(PrivateRoutes.Reservation)
    }

    useEffect(() => {
        if (error)
            tg.HapticFeedback.notificationOccurred('error')
    },[error])

    useEffect(() => {
        if (status)
            tg.HapticFeedback.notificationOccurred('success')
    },[status])

    return (
        <div className='my-booking-description-screen'>
            {localOpenModal==='Отменить' && <ConfirmationModal title='Ты действительно хочешь отменить бронь?' onYesButtonClick={onConfirmCancelingBookingClick} onNoButtonClick={onRejectCancelingBookingClick}/>}

            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}
            {status && <DefaultModal title='Бронь отменена!' buttonText='Готово' onButtonClick={onModalCompleteExit} />}


            <Header
                title={`бронирование ${selectedBookingId}`}
            />
            {loading ?
                <div className='my-booking-description-container'><ColorRingLoader /></div>
                :
                <div className='my-booking-description-container'>


                    <div className='my-booking-description-content'>
                        <div className='my-booking-description-title'>
                            {new Date(selectedBookingDescription?.booking_info?.date*1000).toLocaleDateString()}
                            <span className='my-booking-description-title-time'>
                                НА {new Date(selectedBookingDescription?.booking_info?.start_time*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}-{new Date(selectedBookingDescription?.booking_info?.finish_time*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                            </span>
                        </div>

                        <BookingListItem withoutRedirectClicking={true} id={selectedBookingId} photo={selectedBookingDescription?.room_info?.photo} title={selectedBookingDescription?.room_info?.name} size={selectedBookingDescription?.room_info?.capacity} />

                        <div className='my-booking-description-text'>
                            Описание
                            <div>{selectedBookingDescription?.room_info?.description}</div>
                        </div>

                        <div className='my-booking-description-text'>
                            Техническое оснащение
                            <div>{selectedBookingDescription?.room_info?.equipment}</div>
                        </div>
                    </div>

                    <div className='buttons-footer'>
                        <Button
                            text='Готово'
                            onClick={() => navigate(PrivateRoutes.Reservation)}
                        />
                        <Button
                            text='Отменить бронь'
                            color='black'
                            onClick={onCancelBookingClick}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default ScreenMyBookingDescription;