import React from 'react';
import './WeekDays.css';

function WeekDays() {
    const weekdays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']

    return (
        <div className='weekdays'>
            {weekdays.map((weekday, index) => <div className='weekday' key={index}>{weekday}</div>)}
        </div>
    )
}

export default WeekDays