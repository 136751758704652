import React, {memo} from 'react';
import './Button.css';
import {useTelegram} from "../../../core/hooks/useTelegram";

function Button({text, color, onClick}) {
    const {tg} = useTelegram()
    const onButtonClick = () => {
        onClick()
        tg.HapticFeedback.impactOccurred('light')
    }
    return (
        <button
            className={'button ' + color}
            onClick={onButtonClick}>
                {text}
        </button>
    )
}

export default memo(Button)