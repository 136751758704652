import {useCallback, useEffect} from "react";

const KEY_EVENT_TYPE = 'keyup';

export function useKeyUp(keyName, onKeyUp) {
    const handleEscKey = useCallback((event) => {
        if (event.key === keyName) {
            onKeyUp();
        }
    }, [onKeyUp]);

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
    }, [handleEscKey]);
}