import React, {memo} from 'react';
import './Header.css';
import {useTelegram} from "../../core/hooks/useTelegram";

function Header({title, subtitle='', selectedOffice, selectedDate, modalName, onClickOffice, component}) {
    const {tg} = useTelegram()
    const onOfficeClick = () => {
        onClickOffice(modalName)
        tg.HapticFeedback.impactOccurred('light')
    }

    return (
        <div className='header'>
            <div className='header-header'>
                <div className='office-btn' onClick={onOfficeClick}>
                    {selectedOffice}
                    {
                        onClickOffice &&
                        <svg className='arrow-down' width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 6L0.870834 0L12.1292 0L6.5 6Z" fill="black"/>
                        </svg>
                    }

                </div>
                <div className='selected-date'>
                    {selectedDate}
                </div>
            </div>

            <div className='title'>
                {title}
                <div className='subtitle'>{subtitle}</div>
                {component}
            </div>
        </div>
    )
}

export default memo(Header)