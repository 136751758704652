import React, {useCallback, useEffect, useState} from 'react';
import Header from "../../../../components/Header/Header";
import BookingListItem from "../../../../components/BookingListItem/BookingListItem";
import Button from "../../../../components/UI/Button/Button";
import './RoomDescription.css'
import {
    createBooking,
    fetchEvents,
} from "../../../../core/store/actions/reservationActions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {formatTime} from "../../../../core/utils/functions";
import FilterButton from "../../../../components/UI/FilterButton/FilterButton";
import {Keys, Modals, PrivateRoutes} from "../../../../core/utils/constants";
import DescriptionSetter from "../../../../components/modals/DescriptionSetter/DescriptionSetter";
import {useTelegram} from "../../../../core/hooks/useTelegram";
import ColorRingLoader from "../../../../components/UI/ColorRingLoader/ColorRingLoader";
import DefaultModal from "../../../../components/modals/DefaultModal/DefaultModal";
import {
    clearDescriptionInfo,
    setError,
    setSelectedRoomDescription,
    setStatus
} from "../../../../core/store/slices/reservationSlice";
import {useKeyUp} from "../../../../core/hooks/useKeyUp";

function ScreenRoomDescription() {
    const {loading, error, status, office, date, startTime, finishTime, selectedRoomDescription, selectedRoomId, foundRooms, topic, description, headPerson} = useSelector((state) => state.reservation)


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {tg} = useTelegram()

    const [localStatus, setLocalStatus] = useState('')

    const [openModal, setOpenModal] = useState('')
    const toggleModal = useCallback((modal) => {
        setOpenModal(modal)
    }, [])
    const closeModal = useCallback(() => {
        setOpenModal('')
    }, [])

    useKeyUp(Keys.KEY_NAME_ESC, () => navigate(PrivateRoutes.FoundRooms))
    useKeyUp(Keys.KEY_NAME_ENTER, openModal==='' && onBookButtonClick)

    useEffect(() => {
        dispatch(fetchEvents({}))
        dispatch(clearDescriptionInfo())
        for (const roomsCatalog in foundRooms) {
            for (const room in foundRooms[roomsCatalog]) {
                if (foundRooms[roomsCatalog][room].room_info?.id === selectedRoomId) {
                    dispatch(setSelectedRoomDescription(foundRooms[roomsCatalog][room].room_info))
                    break
                }
            }
        }

        tg.BackButton.show()
        tg.BackButton.onClick(() => {
            navigate(PrivateRoutes.FoundRooms)
            tg.HapticFeedback.impactOccurred('light')
        })

        return () => {
            tg.BackButton.hide()
            tg.BackButton.offClick(() => {
                navigate(PrivateRoutes.FoundRooms)
                tg.HapticFeedback.impactOccurred('light')
            })
        }
    }, [])

    useEffect(() => {
        if (error)
            tg.HapticFeedback.notificationOccurred('error')
    },[error])

    useEffect(() => {
        if (status)
            tg.HapticFeedback.notificationOccurred('success')
    },[status])

    const onBookButtonClick = () => {
        if (topic && description) {
            dispatch(createBooking({selectedRoomId, date, startTime, finishTime, description, topic, headPerson}))
        }
        else {
            setLocalStatus('Сначала заполни тему и описание!')
            toggleModal('Error')
            tg.HapticFeedback.notificationOccurred('error')
        }
    }

    const onModalCompleteExit = () => {
        dispatch(setStatus(null))
        navigate(PrivateRoutes.Reservation)
    }

    return (
        <div className='room-description-screen'>
            {openModal==='Error' && <DefaultModal title={localStatus} buttonText='Готово' onButtonClick={closeModal} />}
            {openModal===Modals.Description && <DescriptionSetter closeModal={closeModal}/>}

            {error && <DefaultModal title={error} buttonText='Готово' onButtonClick={() => dispatch(setError(null))} />}
            {status && <DefaultModal title='Переговорная комната забронирована!' buttonText='Готово' onButtonClick={onModalCompleteExit} />}

            <Header
                title={`Переговорка ${selectedRoomId}`}
                selectedOffice={office}
                selectedDate={date.toLocaleDateString()}
                subtitle={`${formatTime(startTime)}-${formatTime(finishTime)}`}
            />

            <div className='room-description-container'>
                <div className='room-description-content'>
                    <BookingListItem withoutRedirectClicking={true} photo={selectedRoomDescription?.photo} title={selectedRoomDescription?.name} size={selectedRoomDescription?.capacity} />
                    <div className='room-description-text'>
                        Описание
                        <div>{selectedRoomDescription?.description}</div>
                    </div>
                    <div className='room-description-text'>
                        Техническое оснащение
                        <div>{selectedRoomDescription?.equipment}</div>
                    </div>
                    <FilterButton
                        title='Описание мероприятия'
                        text={topic ? topic.title : 'Выбери тему мероприятия'}
                        icon='Description'
                        modalName={Modals.Description}
                        onClick={toggleModal}
                    />
                </div>

                <div className='button-footer'>
                    {loading ?
                        <div><ColorRingLoader /></div>
                        :
                        <Button
                            text='Забронировать'
                            onClick={onBookButtonClick}
                        />
                    }
                </div>
            </div>


        </div>
    );
}

export default ScreenRoomDescription;