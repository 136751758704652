import {TimeType} from "../../utils/constants";

export const checkTelegramPlatform = () => {
    return window.Telegram.WebApp.platform !== 'unknown'
}

export const getAccessTokenOrNullFromStorage = () => {
    return localStorage.getItem('access_token')
}

export const removeAccessToken = () => {
    localStorage.removeItem('access_token')
}

export const putAccessTokenToStorage = (val) => {
    localStorage.setItem('access_token', val)
}

export const getRefreshTokenOrNullFromStorage = () => {
    return localStorage.getItem('refresh_token')
}

export const removeRefreshToken = () => {
    localStorage.removeItem('refresh_token')
}

export const setRefreshToken = (val) => {
    localStorage.setItem('refresh_token', val)
}

export const putTokensToStorage = (access_token, refresh_token) => {
    localStorage.setItem('access_token', access_token)
    localStorage.setItem('refresh_token', refresh_token)
}

export const removeTokens = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
}

export function getTime(timeType) {
    if (timeType===TimeType.Start) {
        return new Date(Math.ceil(new Date().getTime() / (1000 * 60 * 30)) * 1000 * 60 * 30)
    }
    if (timeType===TimeType.Finish) {
        return new Date(Math.ceil(new Date().getTime() / (1000 * 60 * 30)) * 1000 * 60 * 30 + 30 * 60 * 1000)
    }
}