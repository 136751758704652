import ScreenTgLogin from './Mobile/TgLogin/TgLogin'
import React, { useEffect } from 'react';
import { useTelegram } from '../core/hooks/useTelegram';
import {
    Routes,
    Route,
    Navigate,
    useNavigate,
} from 'react-router-dom';
import ScreenReservation from './Mobile/Reservation/Reservation';
import {useDispatch, useSelector} from "react-redux";
import {checkAuthWithHeaderThunk} from "../core/store/actions/authActions";
import ScreenFoundRooms from "./Mobile/Reservation/FoundRooms/FoundRooms";
import ColorRingLoader from "../components/UI/ColorRingLoader/ColorRingLoader";
import ScreenMyBookingDescription from "./Mobile/Reservation/MyBookingDescription/MyBookingDescription";
import ScreenRoomDescription from "./Mobile/Reservation/RoomDescription/RoomDescription";
import ScreenBookingRoomsBrowse from "./Mobile/Reservation/BookingRoomsBrowse/BookingRoomsBrowse";
import {PrivateRoutes, PublicRoutes} from "../core/utils/constants";

function ScreensRoot() {
    const {tg} = useTelegram()
    const {loading, user, accessToken, refreshToken, error} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {

        if (accessToken && refreshToken) {
            dispatch(checkAuthWithHeaderThunk({refreshToken}))
            tg.ready();
            tg.enableClosingConfirmation()
        }
    }, [])

    useEffect(() => {
        if (user) navigate(PrivateRoutes.Reservation)
    }, [user])

    if (error) {
        console.log(error)
    }
    if (loading) {
        return <ColorRingLoader />
    }
    if (!user) {
        return (
            <Routes>
                <Route path={PublicRoutes.SignIn} element={<ScreenTgLogin />} />
                <Route path="*" element={<Navigate to={PublicRoutes.SignIn} />} />
            </Routes>
        )
    }
    if (user) {
        return (
            <Routes>
                <Route path={PrivateRoutes.Reservation} element={<ScreenReservation />} />
                <Route path={PrivateRoutes.FoundRooms} element={<ScreenFoundRooms />} />
                <Route path={PrivateRoutes.MyBookingDescription} element={<ScreenMyBookingDescription />} />
                <Route path={PrivateRoutes.RoomDescription} element={<ScreenRoomDescription />} />
                <Route path={PrivateRoutes.BookingRoomsBrowse} element={<ScreenBookingRoomsBrowse />} />
                <Route path="*" element={<Navigate to={PrivateRoutes.Reservation} />} />
            </Routes>
        )
    }
}

export default ScreensRoot;
