import {createAsyncThunk} from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";
import {putAccessTokenToStorage, putTokensToStorage} from "../utils/functions";

export const tgWebAppSignInThunk = createAsyncThunk(
    'auth/tgWebAppSignIn',
    async ({ auth_data }, { rejectWithValue }) => {
        try {
            const { data } = await AuthService.tgWebAppSignIn(auth_data)
            putTokensToStorage(data.access_token, data.refresh_token)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const checkAuthWithHeaderThunk = createAsyncThunk(
    'auth/checkAuthWithHeader',
    async ({ refreshToken }, { rejectWithValue }) => {
        try {
            const {data} = await AuthService.refreshWithHeader(refreshToken)
            putTokensToStorage(data.access_token, data.refresh_token)
            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const checkAuthWithCookieThunk = createAsyncThunk(
    'auth/checkAuthWithCookie',
    async ({ rejectWithValue }) => {
        try {
            const { data } = await AuthService.refreshWithCookie()
            putAccessTokenToStorage(data.access_token)
            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
