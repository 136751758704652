import React, {memo, useEffect, useRef} from 'react';
import './MyBookings.css';
import Booking from "./Booking/Booking";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchMyBookingsThunk,
} from "../../core/store/actions/reservationActions";
import ColorRingLoader from "../UI/ColorRingLoader/ColorRingLoader";
import {useNavigate} from "react-router-dom";
import {setSelectedBookingId} from "../../core/store/slices/reservationSlice";
import {PrivateRoutes} from "../../core/utils/constants";

function MyBookings() {
    const {loading, myBookings} = useSelector((state) => state.reservation)
    const {user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchMyBookingsThunk({user}))
    }, [])

    useEffect(() => {
        const el = scrollRef.current;
        if (el) {
            const wheelListener = (e) => {
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY * 5,
                    behavior: "smooth"
                });
            };
            el.addEventListener("wheel", wheelListener);
            return () => el.removeEventListener("wheel", wheelListener);
        }
    }, []);

    const selectBooking = (bookingId) => {
        dispatch(setSelectedBookingId(bookingId))
        navigate(PrivateRoutes.MyBookingDescription)
    }

    const scrollRef = useRef(null);

    const onWheel = e => {
        const container = scrollRef.current;
        const containerScrollPosition = scrollRef.current.scrollLeft;

        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
            behavior: 'smooth'
        });
    };

    if (loading) return <div style={{marginTop: '40px'}}><ColorRingLoader /></div>

    if (myBookings.length !== 0) return (
        <div className='your-bookings'>
            <div className='title'>Твои брони</div>
            <div className='count'>{myBookings.length} шт</div>
            <div className='bookings' ref={scrollRef} onWheel={onWheel}>
                {myBookings.map(booking =>
                    <Booking
                        key={booking.booking_info.id}
                        id={booking.booking_info.id}
                        photo={booking.room_info.photo}
                        title={booking.room_info.name}
                        subtitle={
                            `Забронирована на ${new Date(booking.booking_info.date*1000).toLocaleDateString()} 
                             с ${new Date(booking.booking_info.start_time*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                             до ${new Date(booking.booking_info.finish_time*1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`
                        }
                        onClick={selectBooking}
                    />)}
            </div>
        </div>
    )
}

export default memo(MyBookings)