import React from 'react';
import './ConfirmationModal.css'
import Button from "../../UI/Button/Button";
import {useKeyUp} from "../../../core/hooks/useKeyUp";
import {Keys} from "../../../core/utils/constants";

function ConfirmationModal({title, onYesButtonClick, onNoButtonClick}) {

    useKeyUp(Keys.KEY_NAME_ESC, onNoButtonClick)
    useKeyUp(Keys.KEY_NAME_ENTER, onYesButtonClick)

    return (
        <div className='default-modal-bg'>
            <div className='confirmation-modal-main'>
                <div className='confirmation-modal-title'>
                    {title}
                </div>
                <div className='confirmation-modal-buttons'>
                    <Button text='Да' onClick={onYesButtonClick} />
                    <Button text='Нет' onClick={onNoButtonClick} />
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;