import {createAsyncThunk} from "@reduxjs/toolkit";
import ReservationService from "../../services/ReservationService";

export const fetchLocations = createAsyncThunk(
    'reservation/fetchLocations',
    async ({}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getLocations()

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const findRoomsThunk = createAsyncThunk(
    'reservation/findRooms',
    async ({selectedOfficeId, date, startTime, finishTime, numberPeople}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.findRooms(selectedOfficeId, date.setUTCHours(0,0,0,0), startTime.setSeconds(0), finishTime.setSeconds(0), numberPeople)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchMyBookingsThunk = createAsyncThunk(
    'reservation/fetchMyBookings',
    async ({user}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getUserBookings(user.bot_user_id)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchSelectedBookingThunk = createAsyncThunk(
    'reservation/fetchSelectedBooking',
    async ({selectedBookingId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getBooking(selectedBookingId)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchSelectedRoomThunk = createAsyncThunk(
    'reservation/fetchSelectedRoom',
    async ({selectedRoomId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getRoomInfo(selectedRoomId)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchEvents = createAsyncThunk(
    'reservation/fetchEvents',
    async ({}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.getAllEvents()

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const createBooking = createAsyncThunk(
    'reservation/createBooking',
    async ({selectedRoomId, date, startTime, finishTime, description, topic, headPerson}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.bookingCreate(
                selectedRoomId, date.setUTCHours(0,0,0,0), startTime.setSeconds(0), finishTime.setSeconds(0), description, topic.id, headPerson
            )

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const cancelBooking = createAsyncThunk(
    'reservation/cancelBooking',
    async ({selectedBookingId}, { rejectWithValue }) => {
        try {
            const { data } = await ReservationService.bookingCancel(selectedBookingId)

            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)